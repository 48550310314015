import { api } from 'util/api'
import { 
  GetStudentAnswersRequest,
  GetStudentAnswersResponse,
  PutReviewStatusRequest,
  PutReviewStatusResponse,
  PutResolvedStatusRequest,
  PutResolvedStatusResponse
} from 'common/types/student/answers'

export const getStudentAnswers = async (student_id: string, since_date_iso: string, to_date_iso?: string) => {
  return api(GetStudentAnswersRequest, GetStudentAnswersResponse, 'GET', 'student/student_id/answers')(
    {
      params: { student_id },
      query: { since_date_iso, to_date_iso }
    }
  )
}

interface PutReviewProps {
  answer_id: string
  reviewed: boolean
  help_requested: boolean
}
export const putReviewStatus = async ({answer_id, reviewed, help_requested}: PutReviewProps) =>
  api(PutReviewStatusRequest, PutReviewStatusResponse, 'PUT', 'student/answer/answer_id')(
    { params: { answer_id }, body: { reviewed, help_requested } }
  )

interface PutResolvedProps {
  answer_id: string
  resolved: boolean
}
export const putResolvedStatus = async ({ answer_id, resolved }: PutResolvedProps) => 
  api(PutResolvedStatusRequest, PutResolvedStatusResponse, 'PUT', '/student/answer/answer_id/resolve')(
    { params: { answer_id }, body: { resolved }}
  )

