import { types } from 'mobx-state-tree'

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export const Gender = types.enumeration<GenderEnum>(
  'Gender',
  Object.values(GenderEnum)
)
export type Gender = typeof Gender
