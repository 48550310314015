export class ApiError extends Error {
  status: number
  errors: string[]
  constructor(status: number, errors: string[], ...rest) {
    super (...rest)
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }
    this.name = 'ApiError'
    this.status = status
    this.errors = errors
    this.message = `API Error: [${this.errors.join(', ')}]`
  }
}
