import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export const DateFromString = types.snapshotProcessor(
  types.Date,
  {
    preProcessor: (input: string | Date) => {
      if( typeof input === 'string' ){
        const date_time = DateTime.fromISO(input)
        return date_time.toJSDate()
      }
      return input
    }
  }
)

export const DateTimeString = types.refinement(
  'DateTimeString',
  types.string,
  (snapshot:string) => DateTime.fromISO(snapshot).isValid,
  () => 'Is not a valid DateTime'
)
export type DateTimeString = typeof DateTimeString
