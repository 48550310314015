import { types } from 'mobx-state-tree'

const email_regex = /^[^@]+@[^@.]+(\.[^@.]+)+$/

export const Email = types.refinement(
  'Email',
  types.string,
  (snapshot:string) => email_regex.test(snapshot),
  () => 'Is not a valid email address'
)
