import { api } from 'util/api'
import { GetParentRequest, Parent, ParentPostRequest } from 'common/types/parent'

export const createParentRecord = api(ParentPostRequest, Parent, 'POST', 'parent')

export const updateParent = api(ParentPostRequest, Parent, 'PUT', 'parent')

const _getParent = api(GetParentRequest, Parent, 'GET', 'parent')
export const getParent = async (_id) => {
  const data = await _getParent()
  return data
}
