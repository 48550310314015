import { GetAvailabilityRequest, AvailabilityStatus, PutAvailabilityRequest } from 'common/types/tutor/availability_status'
import { api } from 'util/api'

const _getTutorAvailability = api( GetAvailabilityRequest, AvailabilityStatus, 'GET', 'tutor/tutor_id/availability')
export const getTutorAvailability = async (tutor_id: string) => {
  const data = await _getTutorAvailability({params: {tutor_id}})
  return data
}

const _setTutorAvailability = api(PutAvailabilityRequest, AvailabilityStatus, 'PUT', 'tutor/tutor_id/availability')
export const setTutorAvailability = async (tutor_id: string, availability: AvailabilityStatus) => {
  const data = await _setTutorAvailability({params: {tutor_id}, body: availability})
  return data
}
