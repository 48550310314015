import { types } from 'mobx-state-tree'

export enum DifficultyEnum {
  EASY='EASY',
  MEDIUM='MEDIUM',
  HARD='HARD',
  SUPER_HARD='SUPER_HARD',
}

export const Difficulty = types.enumeration(Object.values(DifficultyEnum))
export type Difficulty = typeof Difficulty
