import { types, Instance } from 'mobx-state-tree'
export const Opponent = types.model('Opponent', {
  id: types.string,
  score_multiplier: types.number,
  name: types.string,
  score: types.number,
  difficultyBonus: types.number,
  streakBonus: types.number,
  streak: types.number,
  colour: types.string,
  questionCnt: types.number,
  highestStreak: types.number
})

export type Opponent = Instance<typeof Opponent>


